.cardStyle {
    border-left-style: solid;
    border-left-width: 15px;
    background-color: rgb(229, 229, 229) !important;
}

.cardFull {
    /* border-left-style: solid; */
    border-left-width: 1px;
    background-color: rgb(229, 229, 229) !important;
}

.MargenCheck {
    padding-left: 11px;
    padding-top: 59px;
    padding-right: 11px;
}

.cardStyle:hover {
    /* border-left-style: solid; */
    /* box-sizing: border-box !important; */
    background-color: rgba(248, 248, 248, 0.7) !important;
    /* border-bottom: 2px solid #E5E5E5 !important;
    border-top: 2px solid #E5E5E5 !important;
    border-right: 2px solid #E5E5E5 !important; */
}

.limitCaracter {
    display: block;
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.margenCard {
    padding-right: 20.25rem !important;
}

.tablaTicket .react-bs-table {
    display: none;
}



/*-----------------listadoTicketCards----------------------*/


.navMenu {
    position:absolute;
    width:30%;
    height:100vh;
    
    /* top:0; */
    right:-30%; /*Ocultamos el menú en la parte izquierda para que no se vea a menos que pulsemos sobre la hamburguesa*/
    padding-top:4px;
    /* background-color: rgba(29, 33, 32, 1); */
    /* border-right:4px solid rgba(114, 126, 3, 1); */
    transition:all .5s ease; /*Damos a la caja del menú una transición para que tenga un desplazamiento suave*/
  }

  .menu-toogle {
    position:absolute; /*Lo posicionamos absolutamente para poder darle una posición y poder moverlo según el menú esté abierto o cerrado*/
    width: 45px;
    height: 200px;
    line-height:1.1;
    text-align:center;
    padding-top: 10px;
     top:26%;  /*Le damos una posición top y left en donde queramos que se encuentre la hamburguesa del menú */
    right: 8px; 
    border-radius:5px 0 0 5px;
    background-color: #ff4f00;
    transition: all .5s ease; /*Añadimos una transición para que realice un desplazamineto suave*/
    z-index:10;
    font-size: 21px;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
  }

  .menu-toogleCliente {
    position:absolute; /*Lo posicionamos absolutamente para poder darle una posición y poder moverlo según el menú esté abierto o cerrado*/
    width: 45px;
    height: 200px;
    line-height:1.1;
    text-align:center;
    padding-top: 10px;
    top:34%; /*Le damos una posición top y left en donde queramos que se encuentre la hamburguesa del menú*/
    right: 8px; 
    border-radius:5px 0 0 5px;
    background-color: #ff4f00;
    transition: all .5s ease; /*Añadimos una transición para que realice un desplazamineto suave*/
    z-index:10;
    font-size: 21px;
    color: white;
    font-family: Helvetica, Arial, sans-serif;
  }
    /*Añadimos la haburguesa y le damos unos estilos*/
    .menu-toogle::before {
      /* content:"|||"; */
      
      font-size:56px;
      
      color:black;
    }


    .checkbox {
        display:none; /*Ocultamos el checkbox pues no nos interesa que se vea*/
      }
      .checkbox:checked ~ .navMenu {
        right:0; /*Le quitamos el left negativo para que el menú vuelva a su posición original*/
        /*Fijamos el menú lateral para que se desplace cuando hagamos scroll */
      }
      
      .checkbox:checked ~ .menu-toogle { 
        /* right:25%; */
        right: 30%;
        /* top:22%; */
        position:absolute; /*Fijamos la hamburgues para que se desplace junto con el menú*/
      }

      .checkbox:checked ~ .menu-toogleCliente { 
        right:30%;
        position:absolute; /*Fijamos la hamburgues para que se desplace junto con el menú*/
      }
  
     
  
      #cardsFiltrosContainer{
        overflow-x: hidden;
        

      }

        /* .cardsContainer{ 
        overflow-x: hidden; 
         background: red; 
        display: flex;
        flex-direction: column; 
         width: 67%; 
         padding-right: 1%; 
         margin-right: 4%;
         overflow: hidden;

       }   */
/* 
      #sidebarFiltros{
          overflow: hidden;
          position: fixed;
      } */

      .checkbox:checked ~ .cardsContainer{
          background-color: blue;
        width: 50%; /*Le quitamos el left negativo para que el menú vuelva a su posición original*/
         /*Fijamos el menú lateral para que se desplace cuando hagamos scroll */
      }

      /* #prueba{
          background: cadetblue;
          width: 30%;
      } */
/*       
      #cardStyles{
          width: 50%;
      } */

      .w-65{
        width: 65% !important;
    }

    .w-95{
        width: 95% !important;
    }

/* checkbox mis Tickets*/

/* input checkbox*/
#misTickets{  
  margin:0 7px 0 0 ;
  display: none;
}

.misTickets:checked ~ #misTicketsLabel { 
  background-color: #ff7700;   /* para cambiar el fondo del boton al hacer check o click */
  color: white;
}

#misTicketsLabel{                     
     font-size:13px;
   }