.rc-tabs-tab {
    background-color: white;
}

.rc-tabs-nav-wrap {
    background-color: white;
}

.rc-tabs-tab-active,
.rc-tabs-tab-active {
    color: #007bff !important;
    background-color: white;
    border-bottom: 2px solid;
}

.rc-tabs-ink-bar {
    background-color: #ff4f00;
}

.rc-tabs-top .rc-tabs-tab {
    margin: 0px;
    padding: 10px 15px 10px 10px;
    max-width: 160px;
    color: #000000;
    font-size: 14px;
    font-family: "Montserrat-Bold" !important;
}

.rc-tabs-tab:hover {
    color: green;
}

.rc-tabs-top {
    border-bottom: none;
}


.rc-tabs-bottom .rc-tabs-tab {
    padding-left: 20px;
    padding-right: 20px;
}
