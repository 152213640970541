.cardTicketStyle {
    border: 2px solid #BABABA;
    border-radius: 12px;
}


.box-image img{
    width: 100%;  
    height: 80%;
    display: block;
}