.icono {
    font-size: 16px !important;
}

.icono-11 {
    font-size: 11px !important;
}

.txt-9 {
    color: #262626 !important;
    font-size: 9px !important;
    text-decoration: none !important;
    font-family: "Montserrat" !important;
}

/*  + + + + + texto  + + +  */
.txt-11-n {
    color: #262626 !important;
    font-size: 11px !important;
    text-decoration: none !important;
    font-family: "Montserrat-Bold" !important;
}

.txt-12-n {
    color: #262626 !important;
    font-size: 12px !important;
    text-decoration: none !important;
    font-family: "Montserrat-Bold" !important;
}

.txt-14-n {
    color: #262626 !important;
    font-size: 14px !important;
    text-decoration: none !important;
    font-family: "Montserrat-Bold" !important;
}

.txt-16-n {
    color: #262626 !important;
    font-size: 16px !important;
    text-decoration: none !important;
    font-family: "Montserrat-Bold" !important;
}

.txt-22-n {
    color: #262626 !important;
    font-size: 22px !important;
    text-decoration: none !important;
    font-family: "Montserrat-Bold" !important;
}

/* + = = = = = = = = TEXTO NEGRITA =====  */

.txt-11 {
    color: #262626 !important;
    font-size: 11px !important;
    text-decoration: none !important;
    font-family: "Montserrat" !important;
}

.txt-12 {
    color: #262626 !important;
    font-size: 12px !important;
    text-decoration: none !important;
    font-family: "Montserrat" !important;
}

.txt-14 {
    color: #262626 !important;
    font-size: 14px !important;
    text-decoration: none !important;
    font-family: "Montserrat" !important;
}

.txt-16 {
    color: #262626 !important;
    font-size: 16px !important;
    text-decoration: none !important;
    font-family: "Montserrat" !important;
}

.txt-22 {
    color: #262626 !important;
    font-size: 22px !important;
    text-decoration: none !important;
    font-family: "Montserrat" !important;
}

/* + = = = = = = = = COLOR =====  */
.color-003 {
    color: #003c71 !important;
}

.color-057 {
    color: #0571cd !important;
}

.color-BE1 {
    color: #be1622 !important;
}

.color-FF4 {
    color: #ff4f00 !important;
}

.color-FFC {
    color: #ffc817 !important;
}

.color-262 {
    color: #262626 !important;
}

.color-4AC {
    color: #4ac4e8 !important;
}
